import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'contact',
  components: {
    Location: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Whatsapp: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Whatsapp'),
    Clock: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Clock'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  data() {
    return {
      userData: {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        category: ''
      },
      categoryList: ['Prakerja', 'Program', 'General', 'Corporate'],
      isError: ''
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('contact', ['contactUs', 'contactUsWithCategory']),
    sendMessage: function () {
      this.isError = ''
      if (!this.isValidForm()) {
        this.isError = 'There are empty fields! Please fill in all information.'
        return
      }
      // eslint-disable-next-line no-useless-escape
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!regex.test(this.userData.email)) {
        this.isError = 'Your email format is invalid!'
        return
      }
      this.showLoading()
      this.contactUsWithCategory({
        payloads: {
          email: this.userData.email,
          fullName: this.userData.name,
          message: this.userData.message,
          phoneNumber: this.userData.phoneNumber,
          category: this.userData.category.toUpperCase()
        }
      }).then(this.successSendMessage)
    },
    successSendMessage: function (result) {
      const selectedCategory = `${this.userData.category.toUpperCase()}`
      this.hideLoading()
      this.isError = ''
      if (result.status === 'OK') {
        this.userData = {
          name: '',
          email: '',
          phoneNumber: '',
          message: '',
          category: ''
        }
        showVueToast(`Message is sent for ${selectedCategory}`, 'success', 3000)
      } else {
        this.isError = 'An error occurred on the server. Please try again!'
      }
    },
    isValidForm: function () {
      return this.userData.name !== '' && this.userData.phoneNumber !== '' && this.userData.email !== '' && this.userData.message !== '' && this.userData.category !== ''
    }
  }
}
